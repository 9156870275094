.loading-screen {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: #40495A;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    animation: loading-screen 0.5s 1;
    -webkit-animation: loading-screen 0.5s 1;
    animation-fill-mode: forwards;

    animation-delay: 5s;
    -webkit-animation-delay: 4s;
    -webkit-animation-fill-mode: forwards;
}

.loading-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 380px;
    height: 149px;
}

.loading-name {
    position: relative;
    color: #EAE8E8;
    font-size: 60px;
    margin-bottom: 10px;
}

.loading-role {
    position: relative;
    font-size: 20px;
    color: #a5b5d4;
    margin-bottom: 30px;
}

.loading-bar {
    position: relative;
    width: 150px;
    height: 2px;
    background-color: #2d3440;
    border-radius: 100px;
}

.loading {
    height: 2px;
    width: 100%;
    background-color: white;
}

@keyframes loading-screen {
    from {opacity: 1; z-index: 100;}
    to { opacity: 0; z-index: -10;}
}

@-webkit-keyframes loading-screen{
    from {opacity :1; z-index: 100;}
    to {opacity :0; z-index: -10;}
}