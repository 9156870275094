.navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0px 20px 0px;
}

.name {
    width: 400px;
    text-align: right;
    font-size: 30px;
}

.navlinks {
    margin-left: 60px;
}

.link {
    text-decoration: none;
    margin-left: 30px;
    margin-right: 30px;
}

.link:nth-child(1) {
    color: #FF6E40;
}

.link:nth-child(1):active {
    color: #FF6E40;
}

.link:nth-child(2) {
    color: #FFCD02;
}

.link:nth-child(2):hover {
    color: #FFCD02;
}

.link:nth-child(3) {
    color: #26C6DA;
}

.link:nth-child(3):hover {
    color: #26C6DA;
}

.link:nth-child(4) {
    color: #2ECC71
}

.link:nth-child(4):hover {
    color: #2ECC71
}

@media only screen
    and (max-width: 1100px)
{
    .name {
        text-align: left;
        padding-left: 20px;
        width: auto;
    }

    .link {
        margin-left: 0px;
    }
}

@media only screen
    and (max-width: 580px)
{
    .navbar {
        display: flex;
        flex-direction: column;
    }

    .name {
        margin-bottom: 15px;
        margin-top: -15px;
    }

    .navlinks {
        margin-left: 0;
    }

    .link {
        margin-left: 25px;
        margin-right: 25px;
    }
}